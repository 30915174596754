import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Flexbox from '@mui/material/Grid';
import CreatePass from "../modals/CreatePass";
import { useLocation } from "@reach/router";
import { useQuery } from "../utils"
import { API_URL } from "../../../constants/urls";

const MyAccountResetPass = withSubtheme((props) => {

    // get location
    const location = useLocation();
    const uid = useQuery(location, 'uid');

    return (
        <Flexbox container>
            {uid && (
                <img src={`${API_URL}/stb-forms/${uid}/2/tracking-pixel.png`} style={{display:"none"}} />
            )}
            <Flexbox item xs={12}>
                <CreatePass onClose={() => void 0} />
            </Flexbox>
        </Flexbox>
    );
}, 'resetPassPage')

export default React.memo(MyAccountResetPass);
