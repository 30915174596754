import React from 'react';
import { isEmpty } from 'lodash';
import { useLocation } from "@reach/router";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Alert from '../alert';
import LinkMui from '@mui/material/Link';
import  { trackPassUpdate } from '../helper/eventTracking';

// Icons
import Facebook from '@mui/icons-material/Facebook';
import Google from './google.inline.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useQuery } from "../utils"

const TermsText = ({ dialogTermsProps, termsText, termsLink, termsLabel, privacyLink, privacyLabel, termsLinkProps }) => {
    return(
        <Typography classes={{root: "dialogTerms"}} {...dialogTermsProps}>
            {termsText} {` `}
            {(!isEmpty(termsLink)) && <LinkMui href={termsLink} {...termsLinkProps}>{termsLabel}</LinkMui>}
            {(!isEmpty(termsLink) && !isEmpty(privacyLink)) && <> and </>}
            {(!isEmpty(privacyLink)) && <LinkMui href={privacyLink} {...termsLinkProps}>{privacyLabel}</LinkMui>}.
        </Typography>
    )
}

const StyledDialogContent = (props) => {
    const {
        subtitle,
        showSocialLogin,
        showTerms,
        termsText,
        termsLinkProps,
        termsLink,
        termsLabel,
        privacyLink,
        privacyLabel,
        state,
        services,
        formActionLabel,
        dialogContentProps
    } = props

    const {
        dialogContentTitleProps,
        dialogSocialButtonProps,
        dialogSocialButtonGoogleProps,
        dialogSocialButtonFBProps,
        dialogSeparatorProps,
        dialogSeparatorSpanProps,
        dialogFormControlProps,
        dialogTermsProps,
        // dialogHelperTextProps,
        dialogActionsBtnProps,

    } = dialogContentProps

    // password field logic
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const [validation, setValidation] = React.useState({
        password: false,
        passwordConfirmation: false
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // get location
    const location = useLocation();
    const code = useQuery(location, 'code');
    const uid = useQuery(location, 'uid');

    const handleChange = (prop) => (event) => {
        if (!event.target.value)
            setValidation({...validation, [prop]:true})
        else
            setValidation({...validation, [prop]:false})
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let hasError = false;
        if (isEmpty(values)) {
            setValidation({
                password: true,
                passwordConfirmatio: true
            })
        } else {
            let validationAlt = {}
            let errotStatus;
            Object.entries(validation).map(([key, value]) => {
                if (!values[key]) {
                    errotStatus = true;
                    hasError = true;
                } else {
                    errotStatus = false;
                }
                validationAlt[key] = errotStatus;
            })
            setValidation(validationAlt)
        }

        if (!hasError) {
            let postData = {...values, code: code};
            if (uid) {
                postData = {...postData, uid: uid}
            }
            postData['trackData'] = () => {
                trackPassUpdate({
                    eventType: props.updatePassType
                })
            }
            services.resetPassword(postData);
        }
    };

    // Call social login
    const doSocialLogin = ( provider ) => {
      services.providerLogin(provider);
    }

    return(
        <DialogContent classes={{root: "dialogContent"}}>
            <DialogContentText classes={{root: "dialogContentTitle"}} {...dialogContentTitleProps}>
                {subtitle}
            </DialogContentText>
            {showSocialLogin &&
                <>
                    <Box className={"dialogSocialButtonCont"}>
                        <Button
                            {...dialogSocialButtonProps}
                            classes={{root: "dialogSocialButton"}}
                            onClick={ () => {doSocialLogin('google')}}
                        >
                            <Google {...dialogSocialButtonGoogleProps} />
                        </Button>
                        <Button
                            {...dialogSocialButtonProps}
                            classes={{root: "dialogSocialButton"}}
                            onClick={ () => {doSocialLogin('facebook')}}
                        >
                            <Facebook {...dialogSocialButtonFBProps} />
                        </Button>
                    </Box>

                    <Typography
                        {...dialogSeparatorProps}
                        classes={{root: "dialogSeparator"}}
                    >
                        <Typography classes={{root: "dialogSeparatorSpan"}} {...dialogSeparatorSpanProps}>or login with email</Typography>
                    </Typography>
                </>
            }

            <form noValidate autoComplete="off" id="myAccountResetPassForm">
                <Alert type="alert" message={state.message} severity={state.error ? 'error' : 'success'} />

                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        name="password"
                        onChange={handleChange('password')}
                        error={validation['password']}
                        required={true}
                        label="New Password"
                        classes={{root: "dialogFormControlElem"}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>

                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="confirmPassword"
                        type={values.showPassword ? 'text' : 'password'}
                        name="passwordConfirmation"
                        onChange={handleChange('passwordConfirmation')}
                        error={validation['passwordConfirmation']}
                        required={true}
                        label="Confirm New Password"
                        classes={{root: "dialogFormControlElem"}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>

                {showTerms && termsText &&
                    <TermsText
                        dialogTermsProps={dialogTermsProps}
                        termsText={termsText}
                        termsLinkProps={termsLinkProps}
                        termsLink={termsLink}
                        termsLabel={termsLabel}
                        privacyLink={privacyLink}
                        privacyLabel={privacyLabel}
                    />
                }

                <DialogActions classes={{root: "dialogActions"}}>
                    <Button disabled={state.loading || false} onClick={handleSubmit} {...dialogActionsBtnProps} classes={{root: "dialogActionsBtn"}} id="myAccountResetPassSubmit">
                        {formActionLabel}
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    )
};

export default StyledDialogContent;
